const multiAirportMap = {
  ATL: 'ATLA',
  BKK: 'BKKA',
  BSL: 'EAPA',
  PEK: 'BJSA',
  BFS: 'BFSA',
  BZE: 'BZEA',
  CNF: 'BHZA',
  BRU: 'BRUA',
  EZE: 'BUEA',
  CTU: 'CTUA',
  ORD: 'CHIA',
  CKG: 'CQAA',
  CLE: 'CLEA',
  CMB: 'CMBA',
  DAL: 'QDFA',
  DXB: 'DUBA',
  FRA: 'FRAA',
  GLA: 'GLAA',
  GOA: 'GOAA',
  IAH: 'QHOA',
  IST: 'ISTA',
  CGK: 'JKTA',
  JNB: 'JNBA',
  KBP: 'KIEA',
  KUL: 'KULA',
  STN: 'LONA',
  LAX: 'LAXA',
  MNL: 'MNLA',
  MDE: 'MDEA',
  MEL: 'MELA',
  MEX: 'MEXA',
  MIA: 'QMIA',
  MXP: 'MILA',
  YUL: 'YMQA',
  DME: 'MOWA',
  NGO: 'NAGA',
  NBO: 'NAIA',
  JFK: 'NYCA',
  MCO: 'ORLA',
  KIX: 'OSAA',
  OSL: 'OSLA',
  PAC: 'PACA',
  CDG: 'PARA',
  PHX: 'PHXA',
  KEF: 'REKA',
  GIG: 'RIOA',
  FCO: 'ROMA',
  SJU: 'SJUA',
  GRU: 'SAOA',
  CTS: 'SPKA',
  BFI: 'SEAA',
  GMP: 'SELA',
  PVG: 'SHAA',
  SIN: 'SINA',
  ARN: 'STOA',
  TPE: 'TPEA',
  IKA: 'TEHA',
  TFS: 'TCIA',
  HND: 'TYOA',
  YYZ: 'YTOA',
  YVR: 'VANA',
  VCE: 'VENA',
  WMI: 'WARA',
  IAD: 'WASA',
  ERS: 'WDHA',
  YIA: 'JOGA',
  ZYI: 'ZYIA',
  CMH: 'CMHA',
  RKZ: 'RKZA',
  PDK: 'ATLA',
  DMK: 'BKKA',
  MLH: 'EAPA',
  PKX: 'BJSA',
  BHD: 'BFSA',
  TZA: 'BZEA',
  PLU: 'BHZA',
  CRL: 'BRUA',
  AEP: 'BUEA',
  TFU: 'CTUA',
  MDW: 'CHIA',
  CQW: 'CQAA',
  BKL: 'CLEA',
  RML: 'CMBA',
  DFW: 'QDFA',
  DWC: 'DUBA',
  HHN: 'FRAA',
  PIK: 'GLAA',
  GOX: 'GOAA',
  HOU: 'QHOA',
  SAW: 'ISTA',
  HLP: 'JKTA',
  HLA: 'JNBA',
  IEV: 'KIEA',
  SZB: 'KULA',
  LHR: 'LONA',
  ONT: 'LAXA',
  SGL: 'MNLA',
  EOH: 'MDEA',
  MEB: 'MELA',
  TLC: 'MEXA',
  FLL: 'QMIA',
  LIN: 'MILA',
  YMX: 'YMQA',
  SVO: 'MOWA',
  NKM: 'NAGA',
  WIL: 'NAIA',
  LGA: 'NYCA',
  SFB: 'ORLA',
  ITM: 'OSAA',
  TRF: 'OSLA',
  PTY: 'PACA',
  ORY: 'PARA',
  AZA: 'PHXA',
  RKV: 'REKA',
  SDU: 'RIOA',
  CIA: 'ROMA',
  SIG: 'SJUA',
  CGH: 'SAOA',
  OKD: 'SPKA',
  SEA: 'SEAA',
  ICN: 'SELA',
  SHA: 'SHAA',
  XSP: 'SINA',
  NYO: 'STOA',
  TSA: 'TPEA',
  THR: 'TEHA',
  TFN: 'TCIA',
  NRT: 'TYOA',
  YHM: 'YTOA',
  YXX: 'VANA',
  TSF: 'VENA',
  WAW: 'WARA',
  BWI: 'WASA',
  WDH: 'WDHA',
  JOG: 'JOGA',
  WMT: 'ZYIA',
  LCK: 'CMHA',
  DDR: 'RKZA',
} as { [code: string]: string };

export const normalizeDDRAirport = (code: string) => multiAirportMap[code] || code;
